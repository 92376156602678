import cogoToast from "cogo-toast";
import { GetApi, PostApi, DeleteApi, PutApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


// get rates on the platform
export const getRates = () => {
    return async (dispatch, getState) => {
        dispatch({ type: "Loader"})
      try {
        const res = await GetApi("ratecalculator/coin/rates", "iking2022");
        if (res.status === 200) {
          dispatch({ type: "Rates", data: res.data.message });
        }
        if (res.status === 400) {
          dispatch({ type: "Rate_Error", err: res.data });
        }
      } catch (err) {
        console.log(err);
      }
    };
  };


  // add a new rate functionality
export const addRate = (val) => {
    return async (dispatch, getState) => {
      try {
        const res = await PostApi(
          "ratecalculator/coin/rate",
          {
            coinType: val.coinType,
            usdRateCoin: parseFloat(val.usdRateCoin),
            localCurrencyRate: val.localCurrencyRate
          },
          getToken(),
          "application/json"
        );
        if (res.status === 201) {
          dispatch({ type: "Rate_Success" });
          cogoToast.success("Rate created successfully!");
        }
        if(res.status === 400){
          dispatch({ type: "Rate_Fail", err: res.data});
          cogoToast.error('Coin name already exist!')
        }
      } catch (err) {
        console.log(err)
      }
    };
};


// reset rates addition
export const clearRateSuccess = () => {
    return (dispatch, getState) => {
      dispatch({ type: "ClearRateSuccess" });
    };
  };


// update a new rate functionality
export const updateRate = (val) => {
    return async (dispatch, getState) => {
      try {
        const res = await PutApi(
          "ratecalculator/coin/rate/"+val.id,
          {
            coinType: val.coinType,
            usdRateCoin: parseFloat(val.usdRateCoin),
            localCurrencyRate: val.localCurrencyRate
          },
          getToken(),
          "application/json"
        );
        if (res.status === 200) {
          cogoToast.success("Rate updated successfully!");
        }
        if(res.status === 400){
          cogoToast.error('Coin name already exist!')
        }
      } catch (err) {
        console.log(err)
      }
    };
};



// delete rates funct
export const deleteRate = (id) => {
    return async (dispatch, getState) => {
      try {
        const res = await DeleteApi(
          "ratecalculator/coin/rates/" + id,
          getToken(),
          "application/json"
        );
        if (res.status === 200) {
           dispatch({ type: "DeleteRate" });
          cogoToast.success("Rate deleted successfully!");
        }
        if (res.status === 400) {
          cogoToast.error("Error while deleting rate");
        }
      } catch (err) {
        console.log(err);
      }
    };
};

// get all cointypes on the platform
export const getCoinTypes = () => {
    return async (dispatch, getState) => {
      try {
        const res = await GetApi("ratecalculator/coin/cointypes", "iking2022");
        if (res.status === 200) {
          dispatch({ type: "CoinTypes", data: res.data.message });
        }
        if (res.status === 400) {
          dispatch({ type: "CoinType_Error", err: res.data });
        }
      } catch (err) {
        console.log(err);
      }
    };
  };







