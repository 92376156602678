const initState = {
    subcategory: [],
    loader: false,
    category: []
}
  
  const giftrateReducer = (state = initState, action) => {
    switch (action.type) {
      case "SubCategories":
        return {
          ...state,
          subcategory: action.data,
          loader: false
        }; 
    case 'Loader':
        return{
            ...state,
            loader: true
        } 
    case 'Categories':
        return{
            ...state,
            category: action.data
        }
      default:
        return state;
    }
  };
  
  export default giftrateReducer;
  