import {GetApi} from '../request'


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  

// get all transactions
export const getTransactions = (status, from_date, to_date) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`transaction/all?status=${status}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Transaction", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Transaction_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};

// get filtered transactions
export const getFilteredTransactions = (status, from_date, to_date) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`transaction/all?status=${status}&from_date=${from_date}&to_date=${to_date}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Transaction", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Transaction_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};




