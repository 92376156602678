import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js'
import {connect} from 'react-redux'

Chart.register(
  ...registerables,
);


const DoughnutChart = ({transactionAnalytics}) => {

    const data = {
      labels: transactionAnalytics.map(({transactionName})=> (transactionName)),
      datasets: [
        {
          data: transactionAnalytics.map(({value})=>(value)),
          backgroundColor: [
            "#FFD66B",
            "#5B93FF",
            "#FF8F6B",
          ],
        },
      ],
    };
  
    const options = {
      plugins: {
        title: {
          display: true,
          text: 'Transactions',
          responsive: true,
          animation: {
            animateScale: true,
            color: true,
          },
        },
      },
    };
  
    return (
      <>
        <Doughnut data={data} options={options} />
      </>
    );
  }
   

  const mapStateToProps = (state) => {
    return {
      transactionAnalytics: state.dashboard.transactionAnalytics
    };
  };
  

  export default connect(mapStateToProps)(DoughnutChart);