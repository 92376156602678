import React from 'react';
import styles from '../../pages/Auth/auth.module.css'
import logo_white from '../../assets/icons/ikinghub-white.svg'
import circle from '../../assets/icons/circle_line.svg'

const OnboardLeftLayout = () => {
    return (
        <>
         <div className='col-lg-7 d-none d-lg-block'>
                <div className={styles.auth_left_section}>
                    <div>
                        <h3 className={styles.auth_title}>IKINGHUB</h3>
                        <p className={styles.auth_subtitle}>Even when the day bleeds, we offer you only the best!</p>
                    </div>

                    <div className={styles.auth_logo_div}>
                        <img src={logo_white} alt="logo" />
                    </div>

                    <div className={styles.auth_circle_div}>
                        <img src={circle} alt="circle" />
                    </div>
                </div>
            </div>
        </>
      );
}
 
export default OnboardLeftLayout;