import React from 'react';
import { Link, useParams } from 'react-router-dom';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './trade.module.css'
import { connect } from 'react-redux';
import Moment from "react-moment";
import ImageZoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'

const AdminCrytoDetailsPage = ({loader, trades}) => {

    const {id} = useParams()

    const trade = trades.find((val) => val._id === id);

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Created":
            result =  styles.pending_icon
            break;
          case "Confirmed":
            result = styles.completed_icon
            break;
          case "Failed":
            result =  styles.failed_icon
            break;
          default:
            break;
        }
        return result;
      };

      const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }



    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Trade History</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className={`mt-5 ${styles.details_card}`}>

                <Link to="/trades" className={styles.goback_div}>
                    <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                    <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                </Link>

                <div className='text-center mt-md-3 mt-3'>
                    <h4 className={styles.title}>Trade Details</h4>
                </div>

                <div className='row mt-md-5 mt-4'>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Trade Id</h6>
                            <p className={styles.details_text}>{id}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Date</h6>
                            <p className={styles.details_text}>
                            <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                {trade.createdAt}
                                </Moment></p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Name</h6>
                            <p className={styles.details_text}>
                            {trade.userDetails.firstName} {trade.userDetails.lastName}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Trade Type</h6>
                            <p className={styles.details_text}>Crypto</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Currency Traded</h6>
                            <p className={styles.details_text}>{trade.coinType}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Amount Traded</h6>
                            <p className={styles.details_text}>{trade.amounttInCoin} {trade.coinType}</p>
                        </div>
                    </div>
                  
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Amount Due</h6>
                            <p className={styles.details_text}>NGN {numberWithCommas(trade.amountInLocalCurrency)}</p>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Wallet Address</h6>
                            <p className={styles.details_text}>{trade.walletAddress}</p>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Trade Status</h6>
                            <div className={styles.status_div}>
                                <p 
                                  className={`mt-1 ${getStatusColor(trade.tradeStatus)}`}
                                    >
                                    {trade.tradeStatus}
                                    </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='mb-3'>
                          <h6 className={styles.details_title}>Payment Proof</h6>
                           {
                               trade.hasOwnProperty('paymentImagrUrl') ? 
                                    <ImageZoom>
                                    <img
                                    alt="cards"
                                    src={trade.paymentImagrUrl}
                                    className={`mt-2 ${styles.cardImage}`}
                                    style={{height: '100px'}}
                                    />
                                </ImageZoom> 
                                :
                             <div className={`mt-2`}>
                               <p className={styles.details_text} style={{fontStyle: 'italic'}}>No proof added yet</p>
                            </div>    

                           }
                                  
                        </div> 
                    </div>

                </div>

            </div>
                
            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      trades: state.trade.trades,
      loader: state.trade.load,
      requestSuccess: state.trade.requestSuccess
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        // handleApprove : (id) => dispatch(approveTrade(id)),
        // handleDecline : (id) => dispatch(declineTrade(id)),
        // handleClear : () => dispatch(clearRequest()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminCrytoDetailsPage);