import cogoToast from "cogo-toast";
import { GetApi, PostApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// get all users
export const getUsers= (val) => {
    return async (dispatch, getState) => {
        dispatch({ type: "Loader"})
      try {
        const res = await GetApi("auth/members?role=Exchanger", getToken());
        if (res.status === 200) {
          dispatch({ type: "Users", data: res.data.message});
        }
        if(res.status === 400){
          dispatch({ type: "Users_Error", err: res.data });
        }
      } catch (err) {
       console.log(err)
      }
    };
  };


  // filter users by id
export const filterUsers = (id) => {
    return (dispatch, getState) => {
      dispatch({ type: "filterUsers", data: id });
    };
};


  // suspend user functionality
  export const SuspendUser = (user) =>{
    return async (dispatch, getState) => {
      // let id  = user.userId
      let name = 'Suspend'
      try {
        const res = await PostApi("auth/disable/user", { ...user }, getToken());
        if (res.status === 200) {
            dispatch({type: "UserSuccess", name})
          cogoToast.success('User successfully suspended!', { position: 'top-center', })
        }
        if(res.status === 400){
          cogoToast.error('Error while suspending user')
        }
      } catch (err) {
        // var message = err.response.data
        console.log(err)
      }
    };
  }

  // activate user functionality
  export const ActivateUser = (user) =>{
    return async (dispatch, getState) => {
      let id  = user.userId
      let name = 'Restore'
      try {
        const res = await PostApi("auth/enable/user", { ...user }, getToken());
        if (res.status === 200) {
            
            dispatch({type: "UserSuccess", id, name})
          cogoToast.success('User successfully activated!', { position: 'top-center', })
        }
        if(res.status === 400){
          cogoToast.error('Error while activating user')
        }
      } catch (err) {
       console.log(err)
      }
    }
  }





