const initState = {
    isAuthenticated: false,
    token: "",
    profile: {},
    role: "",
    profilePic: "",
    verified_msg: "",
    verified_status: false,
    resetcode: false,
}
  
  const authReducer = (state = initState, action) => {
    switch (action.type) {
      case "LOGIN_SUCCESS":
        localStorage.setItem("token", action.data.token);
        return {
          ...state,
          isAuthenticated: true,
          token: action.data.token,
          profile: action.data.profile,
          role: action.data.profile.role,
          profilePic: action.data.profile.profilePic,
        };
      case "VerifiedUser":
          return {
            ...state,
            verified_msg: action.data,
            verified_status: true,
          };
      case "VerifiedUserError":
          return {
            ...state,
            verified_msg: action.data,
            verified_status: false,
          };   
      case "VALID_RESETCODE":
        return {
          ...state,
          resetcode: false,
        };
      case "INVALID_RESETCODE":
        return {
          ...state,
          resetcode: true,
        };  
      case "logout":
        return {
          ...state,
          isAuthenticated: false,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  