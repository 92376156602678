import React from 'react'
import styles from './auth.module.css'
import { Form, Formik } from "formik";
import { forgotPasswordValidator } from "../../validationSchema/validator";
import { Link } from "react-router-dom";
import email_icon from '../../assets/icons/email_icon.svg'
import logo_black from '../../assets/icons/ikinghub_black.svg'
import OnboardLeftLayout from '../../components/OnboardingSideBar/sidebar';
import { connect } from 'react-redux';
import { forgotPassword } from '../../store/actions/auth';

const ForgotPasswordPage = ({forgot}) => {

     // forgot func
   const handleSubmit = async (values) => {
     await forgot(values)
    };

    return ( 
        <>
        <div className='row no-gutters'>
            <OnboardLeftLayout/>
            <div className='col-lg-5'>
                <div className={styles.auth_right_section}>

                    <div className='d-md-none d-sm-block'>
                        <img src={logo_black} alt="logo" />
                    </div>

                    <div className='mt-4 mt-md-0'>
                        <h1 className={styles.right_title}>Forgot password?</h1>
                        <p className={`mt-1 ${styles.right_subtitle}`}>Enter your email address and we will help you reset your password</p>
                    </div>

                    {/* form submission */}
                    <Formik
                    onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                    }
                    validationSchema={forgotPasswordValidator}
                    initialValues={{ email: ""}}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                        {/* form section */}
                        <div className="mt-3 mt-md-2">
                            {/* email address */}
                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="email">Email</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="email"
                                    placeholder="johndoe@email.com"
                                    id="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                 <img
                                    src={email_icon}
                                    alt="email"
                                    className={`${styles.email_style}`}
                                    />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.email && errors.email}
                            </small>
                            </div>

                            <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`btn ${styles.btn_login_submit}`}
                            >
                                Send Password Reset Link
                            </button>
                            </div>

                            <div className="mt-2">
                            <p className={`text-center ${styles.login_link}`}>
                                Remember password?{" "}
                                <Link to="/">Back to login</Link>
                            </p>
                            </div>
                        </div>
                        </Form>
                    )}
                    </Formik>
                        
                </div>
            </div>
        </div>
        </>
     );
}

const mapDispatchToProps = (dispatch) => {
    return {
      forgot: (creds) => dispatch(forgotPassword(creds)),
    };
  };

 
export default connect(null, mapDispatchToProps)(ForgotPasswordPage);