import React,{useState, useEffect} from 'react'
import styles from './auth.module.css'
import { Form, Formik } from "formik";
import { loginValidator } from "../../validationSchema/validator";
import { Link, useNavigate } from "react-router-dom";
import eye_off from '../../assets/icons/eye-off.svg'
import eye from '../../assets/icons/eye.svg'
import email_icon from '../../assets/icons/email_icon.svg'
import logo_black from '../../assets/icons/ikinghub_black.svg'
import OnboardLeftLayout from '../../components/OnboardingSideBar/sidebar';
import { loginAdmin } from '../../store/actions/auth';
import { connect } from 'react-redux';

const LoginPage = ({isAuthenticated, login}) => {

    const navigate = useNavigate();

    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

    // login function
   const handleSubmit = async (values) => {
     await login(values); 
    };


    useEffect(()=>{
        if(isAuthenticated){
            navigate('/overview')
        }
    })



    return ( 
        <>
        <div className='row no-gutters'>
           <OnboardLeftLayout />
            <div className='col-lg-5'>
                <div className={styles.auth_right_section}>

                    <div className='d-md-none d-sm-block'>
                        <img src={logo_black} alt="logo" />
                    </div>

                    <div className='mt-4 mt-md-0'>
                        <h1 className={styles.right_title}>Sign In</h1>
                        <p className={`mt-1 ${styles.right_subtitle}`}>With your IkingHub Admin credentials</p>
                    </div>

                    {/* form submission */}
                    <Formik
                    onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                    }
                    validationSchema={loginValidator}
                    initialValues={{ email: "", password: "" }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                        {/* form section */}
                        <div className="mt-3 mt-md-2">
                            {/* email address */}
                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="email">Email</label>
                            <div style={{ position: "relative" }}>
                                <input
                                    className={`${styles.input_style}`}
                                    type="email"
                                    placeholder="johndoe@email.com"
                                    id="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                 <img
                                    src={email_icon}
                                    alt="email"
                                    className={`${styles.email_style}`}
                                    />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.email && errors.email}
                            </small>
                            </div>

                            {/* password */}
                            <div className={`mb-2 ${styles.input_container}`}>
                            <label htmlFor="password">Password</label>

                            <div style={{ position: "relative" }}>
                                <input
                                className={`${styles.input_style}`}
                                placeholder="***********"
                                id="password"
                                type={passwordShown ? "text" : "password"}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                {
                                passwordShown
                                ? 
                                <img
                                src={eye}
                                alt="eye"
                                onClick={togglePasswordVisiblity}
                                className={`${styles.toggle_style}`}
                                />
                                :
                                    <img
                                    src={eye_off}
                                    alt="eye"
                                    onClick={togglePasswordVisiblity}
                                    className={`${styles.toggle_style}`}
                                    />
                                }
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.password && errors.password}
                            </small>
                            </div>

                            <Link
                            to="/forgotpassword"
                            className="text-right"
                            style={{ textDecoration: "none" }}
                            >
                            <p  className={styles.forgot_password_text}>Forgot Password?</p>
                            </Link>

                            <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`btn ${styles.btn_login_submit}`}
                            >
                                Sign In
                            </button>
                            </div>

                           
                        </div>
                        </Form>
                    )}
                    </Formik>
                        
                </div>
            </div>
        </div>
        </>
     );
}


const mapStateToProps = (state) =>{
    return{
        isAuthenticated: state.auth.isAuthenticated,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        login: (creds) => dispatch(loginAdmin(creds)),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);