import { combineReducers } from "redux";
import adminReducer from "./admin";
import authReducer from "./auth";
import cryptorateReducer from "./cryptorates";
import dashboardReducer from "./dashboard";
import giftrateReducer from "./giftrates";
import requestReducer from "./requests";
import settingsReducer from "./settings";
import tradeReducer from "./trades";
import transactionReducer from "./transactions";
import userReducer from "./users";


const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  admin: adminReducer,
  giftrate: giftrateReducer,
  cryptorate: cryptorateReducer,
  transaction: transactionReducer,
  trade: tradeReducer,
  settings: settingsReducer,
  request: requestReducer,
  dashboard: dashboardReducer
});

const rootReducer = (state, action) => {
  if (action.type === "logout") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
  