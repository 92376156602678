import React,{useState} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './rates.module.css'
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import { AddRateValidator } from "../../../validationSchema/validator";
import cogoToast from 'cogo-toast';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateRate } from '../../../store/actions/cryptorates';
import { useParams } from 'react-router-dom';

const EditCryptoRates = ({update, rates}) => {

   const {id} = useParams()
   console.log(id)

   const rate = rates.find((val) => val._id === id);

   const currencyRate = rates.find((val) => val._id === id).localCurrencyRate

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      } = useFormik({
        initialValues: {
          coinType: rate && rate.hasOwnProperty("coinType")
          ? rate.coinType
          : "",
          USDRate: rate && rate.hasOwnProperty("usdRateCoin")
          ? rate.usdRateCoin
          : 0,
          NGNRate: "",
          minimumUsdValue: "",
          maximumUsdValue: ""
        },
        validationSchema: AddRateValidator,
        onSubmit(values, { setSubmitting }) {
          handSubmit(values, setSubmitting);
        },
    });



  const handSubmit = async (values) => {
    let minValue = values.minimumUsdValue
    let maxValue = values.maximumUsdValue

        // check if min/max value already exist in prev array
        const found = addedRate.some(el => parseFloat(el.minimumUsdValue) === parseFloat(minValue) || parseFloat(el.maximumUsdValue) === parseFloat(maxValue));
        if(found){
        cogoToast.warn("There already exist a minimum or maximum value added!")
        }
        else{
            // Push newly added values into the added rate array 
            let res = {
            minimumUsdValue: parseFloat(minValue),
            maximumUsdValue: parseFloat(maxValue),
            ngnRateUsd: parseFloat(values.NGNRate),
            cedisRateUsd: parseFloat(values.GHSRate)
            }
        setAddedRate([
            ...addedRate,
            res
        ])
    }
  }


  const column = [
    {
      name: "Min USD",
      selector: (row) => row.minimumUsdValue,
    },
    {
      name: "Max USD",
      selector: (row) => <span>{`${row.maximumUsdValue}`}</span>,
    },
    {
      name: "NGN Rate",
      selector: (row) => <span>{`${row.ngnRateUsd}`}</span>,
    },
    {
      name: "Actions",
      button: true,
      cell: (row,index) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            handleRemove(row, index);
          }}
        >
          Remove
        </button>
      ),
    },
  ];


  const [addedRate, setAddedRate] = useState(currencyRate)

  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  

  const handleRemove = (row, index) =>{
    let res = [...addedRate]
    res.splice(index, 1)
    setAddedRate(res)
  }


  const handleUpdateRate = async () =>{
    setIsSaveDisabled(true)  
    let result = {
      id: id,
      localCurrencyRate: addedRate,
      usdRateCoin: rate.usdRateCoin,
      coinType: rate.coinType
    }
    await update(result)

    setTimeout(()=>{
      setIsSaveDisabled(false)
    }, 3000)
  }

  
    return ( 
        <>
             <UserSidebar>
            <div>
                <p className="titlehead">Crypto Rates</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">


            <div className={`mt-5 ${styles.rates_card}`}>

                <Link to="/cryptorates" className={styles.goback_div}>
                    <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                    <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                </Link>

                    <h4 className={`mt-3 ${styles.title}`}>Edit Rate</h4>

                    <div className="row">
                    <div className="col-lg-6">

                    {/* form submission */}

                    <form onSubmit={handleSubmit}>
                    {/* Coin Name */}
                    <div 
                    className={`mb-3 ${styles.input_container}`}>
                    <label htmlFor="coinType">Coin Name</label>
                    <input
                        className={`${styles.input_style}`}
                    type="text"
                    placeholder="BTC"
                    onBlur={handleBlur}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    id="coinType"
                    style={{ width: "100%" }}
                    value={values.coinType}
                    disabled
                    />
                    <small style={{ color: "#dc3545" }}>
                    {touched.coinType && errors.coinType}
                    </small>
                    </div>
                 

                    {/* usd rate */}
                    <div 
                    className={`mb-3 ${styles.input_container}`}>
                    <label htmlFor="USDRate">USD Rate</label>
                    <input
                        className={`${styles.input_style}`}
                    type="text"
                    placeholder="0"
                    onBlur={handleBlur}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    id="USDRate"
                    style={{ width: "100%" }}
                    value={values.USDRate}
                    disabled
                    />
                    <small style={{ color: "#dc3545" }}>
                    {touched.USDRate && errors.USDRate}
                    </small>
                    </div>


                    <div className="mt-4">
                    <small>
                    <i
                    className="mdi mdi-information mr-1"
                    style={{ color: "#FF8400" }}
                    ></i>
                    Kindly add a minimum and a maximum breakdown here.
                    </small>
                    </div>

                    <div className="row mt-2">
                    <div className="col-lg-6">
                        {/* ghs rate */}
                    <div 
                        className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="minimumUsdValue">Minimum USD Amount</label>
                        <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="minimumUsdValue"
                        style={{ width: "100%" }}
                        value={values.minimumUsdValue}
                        />
                        <small style={{ color: "#dc3545" }}>
                        {touched.minimumUsdValue && errors.minimumUsdValue}
                        </small>
                    </div>

                    </div>

                    <div className="col-lg-6">
                            {/* Ngn rate */}
                    <div 
                        className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="maximumUsdValue">Maximum USD Amount</label>
                        <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="maximumUsdValue"
                        style={{ width: "100%" }}
                        value={values.maximumUsdValue}
                        />
                        <small style={{ color: "#dc3545" }}>
                        {touched.maximumUsdValue && errors.maximumUsdValue}
                        </small>
                    </div>

                    </div>

                    </div>

                    <div className="row">
                    <div className="col-lg-6">
                            {/* Ngn rate */}
                    <div
                        className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="NGNRate">NGN Rate</label>
                        <input
                        className={`${styles.input_style}`}
                        type="text"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="NGNRate"
                        style={{ width: "100%" }}
                        value={values.NGNRate}
                        />
                        <small style={{ color: "#dc3545" }}>
                        {touched.NGNRate && errors.NGNRate}
                        </small>
                    </div>

                    </div>

                    </div>   

                    <button
                    type="submit"
                    className={`btn mt-4 ${styles.btn_add}`}
                    disabled={isSaveDisabled}
                    >
                    Add
                    </button>
                    <small>
                    <i
                    className="mdi mdi-information mr-1"
                    style={{ color: "#FF8400" }}
                    ></i>
                    The coin name selected is what is displayed to the user.
                    </small>
                    </form>

                    </div>

                    <div className="col-lg-6">
                    
                    <div className="mt-4">
                    <DataTable
                    title="Added Rates"
                    columns={column}
                    data={addedRate}
                    pagination
                    persistTableHead
                    progressPending={false}
                    />
                    </div>

                    <div>
                    <button
                    type="submit"
                    className={`btn mt-4 ${styles.btn_add}`}
                    disabled={addedRate.length === 0 || isSaveDisabled}
                    onClick={handleUpdateRate}
                    >
                        Update Rate
                    </button>
                    </div>


                    </div>

                    </div>



                    </div>



            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
 return {
  rates: state.cryptorate.rates,
 }
}

const mapDispatchToProps = (dispatch) => {
 return {
   update: (creds) => dispatch(updateRate(creds)),
 };
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EditCryptoRates);