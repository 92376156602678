import React from 'react';
import { Link } from 'react-router-dom';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './transaction.module.css'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from "react-moment";

const AdminTransactionDetailsPage = ({transactions}) => {

    const {id} = useParams()

    const transaction = transactions.find((val) => val._id === id);


    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result =  styles.pending_icon
            break;
          case "Successful":
            result = styles.completed_icon
            break;
          case "Failed":
            result =  styles.failed_icon
            break;
          default:
            break;
        }
        return result;
      };


    return ( 
        <>
         <UserSidebar>
            <div>
                <p className="titlehead">Transaction History</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className={`mt-5 ${styles.details_card}`}>
                <Link to="/transactions" className={styles.goback_div}>
                    <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                    <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                </Link>

                <div className='text-center mt-md-3 mt-2'>
                    <h4 className={styles.title}>Transaction Details</h4>
                </div>

                <div className='row mt-md-5 mt-4'>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Transaction Id</h6>
                            <p className={styles.details_text}>{transaction._id}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Date</h6>
                            <p className={styles.details_text}>
                            <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                {transaction.createdAt}
                                </Moment>
                                </p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Name</h6>
                            <p className={styles.details_text}>{transaction.sellerDetails.firstName} {transaction.sellerDetails.lastName}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Type</h6>
                            <p className={styles.details_text}>{transaction.type}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Narration</h6>
                            <p className={styles.details_text}>{transaction.narration}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Amount</h6>
                            <p className={styles.details_text}>NGN {transaction.amount}</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div>
                            <h6 className={styles.details_caption}>Status</h6>
                            <div className={styles.status_div}>
                                <p 
                                className={`mt-1 ${getStatusColor(transaction.status)}`}
                               >{transaction.status}</p>
                            </div>
                        </div>
                    </div>

                </div>

                 </div>


                </div>
            </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
        transactions: state.transaction.transactions,
    };
  };
  

 
export default connect(mapStateToProps)(AdminTransactionDetailsPage);