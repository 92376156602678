const initState = {
    config: {},
    loader: false
  };
  
  const settingsReducer = (state = initState, action) => {
    switch (action.type) {
      case "Config":
        return {
          ...state,
          config: action.data,
        };
    case "StartLoader":
        return {
            ...state,
            loader: true
        }
        case "StopLoader":
        return {
            ...state,
            loader: false
        }
      default:
        return state;
    }
  };
  
  export default settingsReducer;
  