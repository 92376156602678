import React,{useEffect} from 'react';
import styles from './auth.module.css'
import logo_black from '../../assets/icons/ikinghub_black.svg'
import chevron from '../../assets/icons/chevron-left.svg'
import { Link, useParams } from 'react-router-dom';
import mail from '../../assets/icons/mail.svg'
import { connect } from 'react-redux';
import { verifyUser } from '../../store/actions/auth';

const VerifySuccessPage = ({verify, status, msg, }) => {

    const values = useParams();

    useEffect(() => {
        document.body.classList.add("userBg");
      }, []);

    useEffect(() => {
     let {code} = values
        const creds = {
          verificationCode: code
        };
        verify(creds);
     }, [verify, values]);

    return ( 
        <>
         <div className='contain'>
             <div className={styles.verify_div}>
                  <div>
                    <img src={logo_black} alt="logo" className='img-fluid' />
                </div>
                <Link to="/" style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                    <img src={chevron} alt="arrow" style={{width: '17px', height: '17px'}} />
                    <p className={styles.back_home_title}>Back to Login</p>
                </Link>
            </div>

            <div className={styles.verify_card_div}>
                <div className={styles.verify_card}>

                    <div className={styles.verify_icon_div}>
                        <img src={mail} alt="archive" /> 
                    </div>

                    <h3 className={`text-center mt-4 ${styles.verify_title}`}>Email Verification</h3>
                    <p  className={`text-center mt-2 ${styles.verify_subtitle}`}>
                      {status ? `Holla, ${msg} ` : msg }
                    </p>

                    <div className={styles.verify_link_div}>
                        <p>Welcome to Ikingub! <Link to="/" style={{textDecoration: 'none'}} className={styles.link_status}>Sign in here</Link></p>
                    </div>

                </div>
            </div>
       </div>
        </>
     );
}
 

const mapStateToProps = (state) => {
   return {
     msg: state.auth.verified_msg,
     status: state.auth.verified_status,
   };
 }
 
 const mapDispatchToProps = (dispatch) => {
   return {
     verify: (creds) => dispatch(verifyUser(creds)),
   };
 }

export default connect(mapStateToProps, mapDispatchToProps)(VerifySuccessPage);