const initState = {
    requests: [],
    requestSearch: [],
    loader: false,
    loading: false,
    requestsuccess: false
  };
  
  const requestReducer = (state = initState, action) => {
    switch (action.type) {
      case "Requests":
        return {
          ...state,
          requests: action.data,
          requestSearch: action.data
        };
      case "searchRequests":
        let res = state.requestSearch.filter((val) => {
          return (
            val.status
              .toLowerCase()
              .includes(action.data.toLowerCase()) ||
            val.amount.toString().includes(action.data)
          );
        });
        return {
          ...state,
          requests: res,
        };
      case "LoadRequest":
        return {
          ...state,
          loading: true
        }
      case "StopLoadRequest":
        return {
          ...state,
          loading: false
        }
      case "StartLoader":
        return {
          ...state,
          loader: true,
        };
      case "StopLoader":
        return {
          ...state,
          loader: false,
        };
      case 'requestSuccess':
        return{
            ...state,
            requestsuccess: true
        }
    case 'clearRequestSuccess':
        return{
            ...state,
            requestsuccess: false
        }
      default:
        return state;
    }
  };
  
  export default requestReducer;
  