import React, {useState, useEffect} from 'react';
import styles from './auth.module.css'
import { Form, Formik } from "formik";
import { resetPasswordValidator } from "../../validationSchema/validator";
import eye_off from '../../assets/icons/eye-off.svg'
import logo_black from '../../assets/icons/ikinghub_black.svg'
import OnboardLeftLayout from '../../components/OnboardingSideBar/sidebar';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { ResetUsersPassword, verifyResetCode } from '../../store/actions/auth';


const ResetPasswordPage = ({Reset, verifyCode, code}) => {

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);

    const search = useLocation().search;

    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };

    
    const toggleConfirmPasswordVisiblity = () => {
        setPasswordConfirmShown(passwordConfirmShown ? false : true);
       };

    // Verify reset code sent to email if valid!
  useEffect(() => {
    const code = new URLSearchParams(search).get("code");
    verifyCode(code);
  }, [verifyCode, search]);

     //reset func
   const handleSubmit = async (values) => {
    const code = new URLSearchParams(search).get("code");
    var creds = {
        code,
        newPassword: values.password,
    };
     await Reset(creds);
    };


    return (
        <>
          <div className='row no-gutters'>
              <OnboardLeftLayout/>
              <div className='col-lg-5'>
                <div className={styles.auth_right_section}>

                   <Link to="/" className='d-md-none d-sm-block'>
                        <img src={logo_black} alt="logo" />
                    </Link>

                    <div className='mt-4 mt-md-0'>
                        <h1 className={styles.right_title}>Create New Password</h1>
                        <p className={`mt-1 ${styles.right_subtitle}`}>Create a new unique password</p>
                    </div>

                  
                  {
                      code &&
                   <div className='mt-4'>
                        <p
                            className={styles.resetexpiredtext}
                        >
                            The recovery link you selected has either been used to change
                            your password or is invalid. Re-submit your email address to
                            receive a new recovery link.
                        </p>

                        <div className="mt-5">
                            <p className={`text-center ${styles.login_link}`}>
                                Request another recovery link?{" "}
                                <Link to="/forgotpassword">Back to Forgot Passowrd</Link>
                            </p>
                        </div>
                    </div>
                    }

                    {/* form submission */}
                   {
                      !code && 
                   <Formik
                    onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                    }
                    validationSchema={resetPasswordValidator}
                    initialValues={{password: "", confirm_password: "" }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                        {/* form section */}
                        <div className="mt-3 mt-md-2">
                         
                            {/* password */}
                            <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="password">Password</label>

                            <div style={{ position: "relative" }}>
                                <input
                                className={`${styles.input_style}`}
                                placeholder="***********"
                                id="password"
                                type={passwordShown ? "text" : "password"}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                <img
                                src={eye_off}
                                alt="eye"
                                onClick={togglePasswordVisiblity}
                                className={`${styles.toggle_style}`}
                                />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.password && errors.password}
                            </small>
                            </div>

                             {/* password */}
                             <div className={`mb-2 ${styles.input_container}`}>
                            <label htmlFor="password">Confirm Password</label>

                            <div style={{ position: "relative" }}>
                                <input
                                className={`${styles.input_style}`}
                                placeholder="***********"
                                id="confirm_password"
                                type={passwordConfirmShown ? "text" : "password"}
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                <img
                                src={eye_off}
                                alt="eye"
                                onClick={toggleConfirmPasswordVisiblity}
                                className={`${styles.toggle_style}`}
                                />
                            </div>
                            <small style={{ color: "#dc3545" }}>
                                {touched.confirm_password && errors.confirm_password}
                            </small>
                            </div>

                            <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`btn ${styles.btn_login_submit}`}
                            >
                                Create Password
                            </button>
                            </div>
                        </div>
                        </Form>
                    )}
                    </Formik>
                    }
                        
                </div>
            </div>
          </div>
        </>
      );
}

const mapStateToProps = (state) =>{
    return{
        code: state.auth.resetcode,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      Reset: (creds) => dispatch(ResetUsersPassword(creds)),
      verifyCode: (creds) => dispatch(verifyResetCode(creds)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);