import React,{useEffect} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './requests.module.css'
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from 'react-redux';
import Moment from "react-moment";
import { clearRequestSuccess, declineRequest, flutterWavePayment, manualRequest } from '../../../store/actions/requests';


const AdminRequestDetailsPage = ({loader, requests, handleDecline, handleManual, requestsuccess, clearRequest, role}) => {


 const {id} = useParams()

 const navigate = useNavigate()

 const request = requests.find((val) => val._id === id);

  const Approve = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `How would you like to approve this transaction?`,
      buttons: [
        {
          label: "Manual Confirmation",
          onClick: () => ManualApprove(id),
        },
        {
          label: "Flutterwave Approve",
          onClick: () => FlutterwavePay(id),
        },
      ],
    });
  };

  const ManualApprove = () => {
    handleManual(id)
  };

  const FlutterwavePay = () => {
    // handleFlutterPay(id)
    alert('This feature is not available yet for use!')
  };

  
  const Decline = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `You are about to decline this request, Do you wish to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => confirmDecline(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const confirmDecline = () => {
    handleDecline(id)
  };

  const getStatusColor = (val) => {
    let result;
    switch (val) {
      case "Pending":
        result =  styles.pending_icon
        break;
      case "Completed":
        result = styles.completed_icon
        break;
      case "Declined":
        result =  styles.failed_icon
        break;
      default:
        break;
    }
    return result;
  };

  useEffect(()=>{
    if(requestsuccess){
        setTimeout(()=>{
            clearRequest()
            navigate('/requests')
        },[2000])
    }
  },[requestsuccess, navigate, clearRequest])

    return (  
        <>
               <UserSidebar>
            <div>
                <p className="titlehead">Request History</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className={`mt-5 ${styles.details_card}`}>

                <Link to="/requests" className={styles.goback_div}>
                    <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                    <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                </Link>

                <div className='text-center mt-md-3 mt-2'>
                    <h4 className={styles.title}>Withdrawal Details</h4>
                </div>

                <div className='row mt-md-5 mt-4'>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Id</h6>
                            <p className={styles.details_text}>{request._id}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Date Requested</h6>
                            <p className={styles.details_text}>
                            <Moment format="MMMM Do, YYYY">
                                {request.createdAt}
                                </Moment></p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Name</h6>
                            <p className={styles.details_text}>{request.userDetails.firstName} {request.userDetails.lastName}</p>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Amount</h6>
                            <p className={styles.details_text}>NGN {request.amount}</p>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Account Name</h6>
                            <p className={styles.details_text}>{request.accountName}</p>
                        </div>
                    </div>

                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Account Number</h6>
                            <p className={styles.details_text}>{request.accountNumber}</p>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Bank Name</h6>
                            <p className={styles.details_text}>{request.bankName}</p>
                        </div>
                    </div>
                  
                    <div className='col-lg-6 mb-4'>
                        <div>
                            <h6 className={styles.details_caption}>Narration</h6>
                            <p className={styles.details_text}>{request.narration}</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div>
                            <h6 className={styles.details_caption}>Status</h6>
                            <div className={styles.status_div}>
                                <p 
                                className={`mt-1 ${getStatusColor(request.status)}`}
                                >{request.status}</p>
                            </div>
                        </div>
                    </div>

                </div>


                {/* approve and decline request  */}
                {
                    request.status === 'Pending' &&

                    <div className={`mt-4 ${styles.cta_btn}`}>
                        <button
                            type="submit"
                            className={`btn ${styles.btn_approve}`}
                            disabled={loader || role === "SubAdmin"}
  
                            onClick={() => {
                            Approve();
                            }}
                        >
                            Approve Request
                        </button>
                        <button
                            type="submit"
                            className={`btn ${styles.btn_decline}`}
                            disabled={loader || role === "SubAdmin"}
                            onClick={() => {
                            Decline();
                            }}
                        >
                            Decline Request
                        </button>
                        
                    </div>
                }

             </div>

            </div>
        </div>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        requests: state.request.requests,
        loader: state.request.loader,
        requestsuccess: state.request.requestsuccess,
        role: state.auth.role,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      handleDecline: (id) => dispatch(declineRequest(id)),
     handleManual: (id) => dispatch(manualRequest(id)),
     handleFlutterPay: (id) => dispatch(flutterWavePayment(id)),
     clearRequest: () => dispatch(clearRequestSuccess()),
    };
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminRequestDetailsPage);