
import { GetApi, PutApi  } from "../request";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// get withdrawal settings
export const getWithdrawSetings = () => {
    return async (dispatch, getState) => {
       dispatch({ type: "StartLoader" });
      try {
        const res = await GetApi("adminconfig", getToken());
        if (res.status === 200) {
          dispatch({ type: "Config", data: res.data.message });
          dispatch({ type: "StopLoader" });
        }
        if (res.status === 400) {
          dispatch({ type: "Config_Error", err: res.data });
          dispatch({ type: "StopLoader" });
        }
      } catch (err) {
        console.log(err);
      }
    };
  };
  
  // update withdrawal settings
  export const updateWithdrawSettings = (val) => {
    return async (dispatch, getState) => {
      var id = getState().settings.config._id
      try {
        const res = await PutApi(
          "adminconfig/" + id,
          {
            minimumWithdrawer: parseFloat(val.minAmount),
            maximumWithdrawer: parseFloat(val.maxAmount),
          },
          getToken(),
          "application/json"
        );
        if (res.status === 200) {
          dispatch({ type: "Config_Update" });
          cogoToast.success("Configuration updated successfully!");
        }
        if (res.status === 400) {
          dispatch({ type: "Config_Fail", err: res.data.message });
          cogoToast.error("Oops an error occured!");
        }
      } catch (err) {
        console.log(err);
      }
    };
  };

  

