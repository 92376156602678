const initState = {
    users: [],
    loader: false,
    user: {},
}
  
  const userReducer = (state = initState, action) => {
    switch (action.type) {
      case "Users":
        return {
          ...state,
          users: action.data,
          loader: false
        }; 
    case "filterUsers":
        const user = state.users.find((val) => val._id === action.data);
        return {
            ...state,
            user,
        }; 
    case 'Users_Error':
        return{
            ...state,
            loader: false
        }  
    case 'Loader':
        return{
            ...state,
            loader: true
        } 
    case "UserSuccess":
      let result;
      switch (action.name) {
        case "Suspend":
          result = {
            ...state.user,
            isEnabled: false
          }
          break;
        case "Restore":
          result = {
            ...state.user,
            isEnabled: true,
          };
          break;
        default:
          break;
      }
      return {
        ...state,
        user: result,
      };
      case "logout":
        return {
          ...state,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  