const initState = {
    admin: [],
    loader: false,
}
  
  const adminReducer = (state = initState, action) => {
    switch (action.type) {
      case "SubAdmin":
        return {
          ...state,
          admin: action.data,
          loader: false
        };
    case "AdminSuccess":
        let res;
        switch (action.name) {
            case "Suspend":
            res = state.admin.map((item) =>
                item._id === action.id
                ? { ...item, isEnabled: false }
                : [...state.admin]
            );
            break;
            case "Restore":
            res = state.admin.map((item) =>
                item._id === action.id
                ? { ...item, isEnabled: true }
                : [...state.admin]
            );
            break;
            default:
            break;
        }
        return {
            ...state,
            admin: res,
        }; 
    case 'Loader':
        return{
            ...state,
            loader: true
        } 
      default:
        return state;
    }
  };
  
  export default adminReducer;
  