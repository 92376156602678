const initState = {
    transactions: [],
    loader: false
  };
  
  const transactionReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Transaction':
          return {
                ...state,
                transactions: action.data,
                loader: false
            }
        case 'Loader':
            return{
                ...state,
                loader: true
            }
        case 'Transaction_Error':
            return{
                ...state,
                loader: false
            }
      default:
        return state;
    }
  };
  
  export default transactionReducer;
  