import React,{useState, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './trade.module.css'
import DataTable from "react-data-table-component";
import hamburger from "../../../assets/icons/align-left.svg";
import Modal from "../../../components/Modals/FilterModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik } from "formik";
import { filterValidator } from "../../../validationSchema/validator";
import { useNavigate } from 'react-router-dom';
import { getCryptoTrade, getFilteredCryptoTrade, getFilteredGiftCardsTrade, getGiftCardsTrade, searchTrade } from '../../../store/actions/trades';
import { connect } from 'react-redux';
import Moment from "react-moment";


const AdminTrades = ({fetchTrades, trades, loader, fetchFilteredTrades, fetchCryptoTrades, fetchFilteredCryptoTrade, searchTrade}) => {

const [search, setSearchValue] = useState("");

const navigate = useNavigate()

const [showFilterModal, setFilterModal] = useState(false);

const [startDate, setStartDate] = useState(new Date());

const [endDate, setEndDate] = useState(new Date());


  useEffect(()=>{
    let status = 'All'
    fetchTrades(status)
  },[fetchTrades])


const [initialTab, setTab] = useState(1);

  const [tabData] = useState([
    { id: 1, name: "tab-1", text: "Giftcard" },
    { id: 2, name: "tab-2", text: "Crypto" },
  ]);

  // tab layout
  const tabLayout = tabData.map((item) => (
    <li key={item.id}
    onClick={() => handleToggle(item.id)}
    >
        <p 
          className={initialTab === item.id ? `${styles.tab} ${styles.tab_active}` : `${styles.tab}`}
        >{item.text}</p>
    </li>
  ));

  const handleToggle = (id) =>{
    setTab(id)
    let status = 'All'
    if(id === 1){
      fetchTrades(status)
    }
    else{
      fetchCryptoTrades(status)
    }
  }

  const columns = [
        {
          name: "Type",
          cell: (row) => (
            <span className={styles.recent_table_row}>
              Giftcard
              </span>
          ),
        },
        {
          name: "Card Name",
          cell: (row) => (
            <span className={styles.recent_table_row}>
              <span className={styles.recent_table_row}>{
              `${row.hasOwnProperty('subCategoryDetails') ? row.subCategoryDetails.categoryname : 'Giftcard'}`
              }</span></span>
          ),
        },
        {
          name: "Card Amount",
          cell: (row) => (
            <span className={styles.recent_table_row}>
              {`USD ${row.hasOwnProperty('cardAmount') ? row.cardAmount : 0}`}</span>
          ),
        },
        {
          name: "Date Initialized",
          cell: (row) => (
            <span className={styles.recent_table_row}>
               <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
            </span>
          ),
        },
        {
          name: "Status",
          cell: (row) => (
            <span className={getStatusColor(row.tradeStatus)}>{`${row.tradeStatus}`}</span>
          ),
        },
        {
          name: "Action",
          button: true,
          cell: (row) => (
            <button
            className="btn btn-sm btn-view"
            onClick={() => {
              ViewRequest(row._id);
            }}
          >
            View
          </button>
          ),
        },
    ];

    const column1 = [
      {
        name: "Type",
        cell: (row) => (
          <span className={styles.recent_table_row}>
            Crypto
            </span>
        ),
      },
      {
        name: "Coin Name",
        cell: (row) => (
          <span className={styles.recent_table_row}>
            {`${row.coinType}`}</span>
        ),
      },
      {
        name: "Coin Amount",
        cell: (row) => (
          <span className={styles.recent_table_row}>
            {`${row.amounttInCoin}`}</span>
        ),
      },
      {
        name: "Date Initialized",
        cell: (row) => (
          <span className={styles.recent_table_row}>
           <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
          </span>
        ),
      },
      {
        name: "Trade Status",
        cell: (row) => (
          <span className={getStatusColor(row.tradeStatus)}>{`${row.tradeStatus}`}</span>
        ),
      },
      {
        name: "Action",
        button: true,
        cell: (row) => (
          <button
          className="btn btn-sm btn-view"
          onClick={() => {
            ViewRequest(row._id);
          }}
        >
          View
        </button>
        ),
      },
    ];

  
    const ViewRequest = (id) =>{
      if(initialTab === 1){
        navigate(`/trade/giftcard/${id}`)
      }
      else{
         navigate(`/trade/crypto/${id}`)
      }
    }

      
   const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending_icon";
            break;
         case "Created":
              result = "pending_icon";
              break;
          case "Completed":
            result = "completed_icon";
            break;
         case "Confirmed":
            result = "completed_icon";
            break;
          case "Declined":
            result = "failed_icon";
            break;
          case "Failed":
              result = "failed_icon";
              break;
          default:
            result = "failed_icon";
            break;
        }
        return result;
    };

    
    const handleChange = (e) => {
       setSearchValue(e.target.value);
    };

    useEffect(() => {
      searchTrade(search)
    }, [search, searchTrade]);



    const formatDate = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  
      return [year, month, day].join("-");
    };

    const handleSubmit = async (values, setSubmitting) => {
      let res;
      res = {
        from_date: formatDate(startDate),
        to_date: formatDate(endDate),
        status: values.status,
      };
      console.log(res);
      setFilterModal(false);
      if(initialTab === 1){
       await fetchFilteredTrades(values.status, formatDate(startDate), formatDate(endDate))
      }
      else{
        await fetchFilteredCryptoTrade(values.status, formatDate(startDate), formatDate(endDate))
      }
    };


  

    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Trades</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <Modal
              title="Filter"
              show={showFilterModal}
              onClose={() => setFilterModal(false)}
            >
               <div>
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={filterValidator}
                initialValues={{
                  amount: "",
                  status: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* Date*/}
                    <div className="row mt-4">
                      <div className="col-lg-6">
                        <div 
                         className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="startDate">From Date</label>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className={`${styles.filter_input_style}`}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                         className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="startDate">To Date</label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className={`${styles.filter_input_style}`}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                    </div>

                    {/* status */}
                    <div 
                     className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        values={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue=""
                        className={`${styles.filter_input_style}`}
                        id="status"
                      >
                        {
                          initialTab === 1  ?
                          <>
                          <option value="" disabled>--Select--</option>
                          <option value="All">All</option>
                          <option value="Pending">Pending</option>
                          <option value="Completed">Completed</option>
                          <option value="Declined">Declined</option>
                          </>
                          :
                          <>
                            <option value="" disabled>--Select--</option>
                            <option value="All">All</option>
                            <option value="Created">Created</option>
                            <option value="Confirmed">Confirmed</option>
                            <option value="Failed">Failed</option>
                          </>

                        }
                      </select>
                      <small style={{ color: "#dc3545" }}>
                        {touched.status && errors.status}
                      </small>
                    </div>

                    <div className="text-center mt-4 ">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_filter}`}
                      >
                        Apply Filter
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            </Modal>


                {/* trades section table */}
                <div className={`mt-5 mb-5 ${styles.trades_div}`}>

                     {/* filter layout and search */}
                    <div className={styles.trade_filter_container}>
                        <div className={styles.transaction_search}>
                         <div className={`mb-3 mb-md-0 ${styles.search_container}`}>
                                <input
                                    className={styles.input_style}
                                    type="text"
                                    placeholder="Search by Status"
                                    id="reference"
                                    value={search}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    <div className='ml-lg-4 ml-0'>
                    <button
                        className={`btn ${styles.filter_div}`}
                        onClick={() => setFilterModal(true)}
                        >
                        <img
                            src={hamburger}
                            width="20"
                            height="20"
                            alt="hamburger"
                            className="mr-2"
                        />
                        Filter By
                        </button>
                        </div>
                    </div>

                    <div className={`mt-4 mb-3 ${styles.tab_div}`}>
                            <ul className={styles.tab_list}>
                                {tabLayout}
                            </ul>
                     </div>    

                  {
                    initialTab === 1 ?
                    <DataTable
                    title=""
                    pagination
                    persistTableHead
                    columns={columns}
                    data={trades}
                    progressPending={loader}
                    />
                    :
                     <DataTable
                      title=""
                      pagination
                      persistTableHead
                      columns={column1}
                      data={trades}
                      progressPending={loader}
                    />
                  }

                </div>

            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
  return {
    trades: state.trade.trades,
    loader: state.trade.loader
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTrades: (status) => dispatch(getGiftCardsTrade(status)),
    fetchCryptoTrades: (status) => dispatch(getCryptoTrade(status)),
    fetchFilteredCryptoTrade: (status, from, end) => dispatch(getFilteredCryptoTrade(status, from, end)),
    fetchFilteredTrades: (status, from, end) => dispatch(getFilteredGiftCardsTrade(status, from, end)),
    searchTrade: (val) => dispatch(searchTrade(val)),
  };
};

 
export default connect(mapStateToProps, mapDispatchToProps)(AdminTrades);