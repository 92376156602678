import cogoToast from "cogo-toast";
import { GetApi, PostApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// get all subadministrators
export const getSubAdmin = (val) => {
    return async (dispatch, getState) => {
        dispatch({ type: "Loader"})
      try {
        const res = await GetApi("auth/members?role=SubAdmin", getToken());
        if (res.status === 200) {
          dispatch({ type: "SubAdmin", data: res.data.message});
        }
        if(res.status === 400){
          dispatch({ type: "Users_Error", err: res.data });
        }
      } catch (err) {
       console.log(err)
      }
    };
  };


  // Add new subadministrators
  export const AddSubAdmin = (val) =>{
    return async (dispatch, getState) => {
      try {
        const res = await PostApi("auth/admin/new", { ...val }, getToken());
        if (res.status === 201) {
            dispatch({type: "AdminSuccess"})
          cogoToast.success('Administrator successfully added!', { position: 'top-center', })
        }
        if(res.status === 400){
          cogoToast.error('Error while adding administrator')
        }
      } catch (err) {
        // var message = err.response.data
        console.log(err)
      }
    };
  }


   // suspend admin functionality
   export const SuspendAdmin = (user) =>{
    return async (dispatch, getState) => {
        let id  = user.userId
      let name = 'Suspend'
      try {
        const res = await PostApi("auth/disable/user", { ...user }, getToken());
        if (res.status === 200) {
            dispatch({type: "AdminSuccess", id, name})
          cogoToast.success('Admin successfully suspended!', { position: 'top-center', })
        }
        if(res.status === 400){
          cogoToast.error('Error while suspending admin')
        }
      } catch (err) {
        // var message = err.response.data
        console.log(err)
      }
    };
  }

  // activate adminn functionality
  export const ActivateAdmin = (user) =>{
    return async (dispatch, getState) => {
      let id  = user.userId
      let name = 'Restore'
      try {
        const res = await PostApi("auth/enable/user", { ...user }, getToken());
        if (res.status === 200) {
            
            dispatch({type: "AdminSuccess", id, name})
          cogoToast.success('Admin successfully activated!', { position: 'top-center', })
        }
        if(res.status === 400){
          cogoToast.error('Error while activating admin')
        }
      } catch (err) {
       console.log(err)
      }
    }
  }

 





