import React,{useEffect} from 'react';
import styles from './Settings.module.css'
import UserSidebar from '../../../components/Sidebar/sidebar';
import { Form, Formik } from "formik";
import { settingsValidator } from "../../../validationSchema/validator";
import { getWithdrawSetings, updateWithdrawSettings } from '../../../store/actions/settings';
import { connect } from 'react-redux';

const AdminWithdrawSettings = ({ 
    minimumAmount,
    maximumAmount,
    updateSettings,
    configFetch,
    config,
    role,}) => {


        // make call to fetch all settings
  useEffect(() => {
    configFetch();
  }, [configFetch]);

    const handleSubmit = async (values) => {
        console.log(values);
        await updateSettings(values)
    };

    
    return ( 
        <>
          <UserSidebar>
            <div>
                <p className="titlehead">Settings</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className={`mt-5 ${styles.settings_card}`}>

                <h5 className={styles.title}>Withdrawal Settings</h5>

                <div className='mt-4'>

                        {/* form submission */}
                    <Formik
                    onSubmit={(values, { setSubmitting }) =>
                        handleSubmit(values, setSubmitting)
                    }
                    validationSchema={settingsValidator}
                    enableReinitialize={true}
                    initialValues={{
                        minAmount:  config && config.hasOwnProperty("minimumWithdrawer")
                        ? minimumAmount.minimumWithdrawer
                        : 0,
                        maxAmount: config && config.hasOwnProperty("maximumWithdrawer")
                        ? maximumAmount.maximumWithdrawer
                        : 0
                    }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form onSubmit={handleSubmit}>

                        {/* Maximum Withdrawal */}
                        <div>
                            <div
                            className={`mb-3 ${styles.input_container}`}
                            style={{ position: "relative" }}
                            >
                            <label htmlFor="maxAmount">Maximum Withdrawal Amount (NGN)</label>
                            <input
                                type="text"
                                name="maxAmount"
                                placeholder="0"
                                style={{ color: "black" }}
                                className={`${styles.input_style}`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                id="maxAmount"
                                value={values.maxAmount}
                            />

                            <small style={{ color: "#dc3545" }}>
                                {touched.maxAmount && errors.maxAmount}
                            </small>
                            </div>
                        </div>

                        {/* Minimum Withdrawal */}
                        <div>
                            <div
                            className={`mb-3 ${styles.input_container}`}
                            style={{ position: "relative" }}
                            >
                            <label htmlFor="minAmount">Minimum Withdrawal Amount (NGN)</label>
                            <input
                                type="text"
                                className={`${styles.input_style}`}
                                name="minAmount"
                                placeholder="0"
                                style={{ color: "black" }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                id="minAmount"
                                value={values.minAmount}
                            />

                            <small style={{ color: "#dc3545" }}>
                                {touched.minAmount && errors.minAmount}
                            </small>
                            </div>
                        </div>

                        <div className="text-center mt-4">
                            <button
                            type="submit"
                            disabled={isSubmitting || role === "SubAdmin"}
                            className={`btn ${styles.btn_change}`}
                            >
                           Update Settings
                            </button>
                        </div>
                        </Form>
                    )}
                    </Formik>
                </div>

                </div>


                
                </div>
            </div>
        </>
     );
}
 

const mapStateToProps = (state) => {
    return {
      minimumAmount: state.settings.config,
      maximumAmount: state.settings.config,
      loader: state.settings.loader,
      config: state.settings.config,
      role: state.auth.role,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      configFetch: () => dispatch(getWithdrawSetings()),
      updateSettings: (creds) => dispatch(updateWithdrawSettings(creds)),
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(AdminWithdrawSettings);