const initState = {
    rates: [],
    loader: false,
    cointypes: [],
    ratesuccess: false,
}
  
  const cryptorateReducer = (state = initState, action) => {
    switch (action.type) {
    case "Rates":
        return {
            ...state,
            rates: action.data,
            loader: false
        };
    case 'Loader':
        return{
            ...state,
            loader: true
        }
    case "Rate_Success":
        return {
            ...state,
            ratesuccess: true,
        }; 
    case "ClearRateSuccess":
        return {
            ...state,
            ratesuccess: false,
        };
    case 'CoinTypes':
        return{
            ...state,
            cointypes: action.data
        }
      default:
        return state;
    }
  };
  
  export default cryptorateReducer;
  