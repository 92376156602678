import React from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './users.module.css'
import { Link } from 'react-router-dom';
import userImg from '../../../assets/images/user.svg'
import { connect } from 'react-redux';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ActivateUser, SuspendUser } from '../../../store/actions/users';

const UserInformation = ({user, details, HandleActivate, HandleSuspend}) => {

    const accountLayout =
    details && details.length ? (
      details.map((value) => {
        return (
          <div key={value.id} className="col-lg-6 mt-4">
              <div className={styles.accountDiv}>
                <p className={`${styles.accountInfo}`}>{value.accountNumber}</p>
                    <p className={`mt-1 ${styles.accountInfo}`}>{value.accountName}</p>
                    <p className={`mt-1 ${styles.accountInfo}`}>{value.bankName}</p>

             </div>
          </div>
        );
      })
    ) : (
      <div></div>
    );

    const Suspend = (id, status) => {
        confirmAlert({
          title: "Confirm to submit",
          message: `You are about to ${
            status ? "suspend" : "restore"
          } this user, Do you wish to continue?`,
          buttons: [
            {
              label: "Yes",
              onClick: () => Approval(id, status),
            },
            {
              label: "No",
            },
          ],
        });
      };
    
      // function to restore or suspend
      const Approval = (id, status) => {
        const values = {
          userId: id,
        };
        if (status) {
          HandleSuspend(values);
        } else {
          HandleActivate(values);
        }
      };


    return (  
        <>
               <UserSidebar>
            <div>
                <p className="titlehead">User Information</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className={`mt-5 ${styles.details_card}`}>

                    <Link to="/users" className={styles.goback_div}>
                        <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                        <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                    </Link>

                    <div className='text-center mt-md-3 mt-3'>
                        <h4 className={styles.title}>Profile Details</h4>
                    </div>

                    <div className={`mt-4 ${styles.profile_div}`}>
                        <div>
                            <img src={userImg} alt="profile" className={`img-fluid ${styles.userImage}`} />
                        </div>
                        <div className='text-center text-md-left mt-4 mt-lg-0'>
                            <h6 className={styles.title}>Wallet Balance (NGN)</h6>
                            <h5 className='mt-2'> {user.waletBalance ? user.waletBalance.toFixed(2) : 0}</h5>
                        </div>
                    </div>

                    <div className={`mt-5 ${styles.userInfoDiv}`}>
                        <p className={styles.userInfo}> {user.firstName ? user.firstName : 'Nill'} {user.lastName ? user.lastName : 'Nill'}</p>
                    </div>

                    <div className={`mt-3 ${styles.userInfoDiv}`}>
                        <p className={styles.userInfo}> {user.email ? user.email : "your@email.com"}</p>
                    </div>

                    <div className={`mt-3 ${styles.userInfoDiv}`}>
                        <p className={styles.userInfo}> {user.phoneNumber ? user.phoneNumber : "+233 0000 0000"}</p>
                    </div>

                    <div className={`mt-3 ${styles.userInfoDiv}`}>
                        <p className={styles.userInfo}> {user.isEnabled ? "Active" : "Inactive"}</p>
                    </div>

                    <div className='text-center mt-4'>
                        <p className='mb-0'>Account Details</p>
                    </div>

                    <div className='row mt-3'>
                        {accountLayout}
                    </div>

                    {details && details.length === 0 ? (
                            <div
                            className="text-center mt-1"
                            style={{ fontStyle: "italic", fontSize: 14 }}
                            >
                            <p>There is no account details attached yet!</p>
                            </div>
                        ) : (
                            ""
                    )}

                    <div className='text-center mt-lg-5 mt-4'>
                        <button
                         onClick={() => {
                            Suspend(user._id, user.isEnabled);
                          }}
                          className={
                            user.isEnabled
                              ? `btn ${styles.btn_suspend}`
                              : `btn ${styles.btn_restore}`
                          }
                         >
                          {user.isEnabled ? "Suspend User" : "Restore User"}
                        </button>
                    </div>

                </div>
                
            </div>
        </div>
        </>
    );
}

const mapStateToProps = (state) => {
   return {
     user: state.user.user,
     details: state.user.user.accountDetails,
   };
 }
 
 const mapDispatchToProps = (dispatch) => {
   return {
    HandleSuspend: (value) => dispatch(SuspendUser(value)),
    HandleActivate: (value) => dispatch(ActivateUser(value)),
   };
 }
 
export default connect(mapStateToProps, mapDispatchToProps)(UserInformation);