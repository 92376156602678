import React,{useEffect, useState} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import { useNavigate } from 'react-router-dom';
import styles from './rates.module.css'
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import { AddGiftcardValidator } from "../../../validationSchema/validator";
import { CreateSubCategory, deleteGiftCards, getCategory, getSubCategory } from '../../../store/actions/giftrates';
import { connect } from 'react-redux';
import Moment from "react-moment";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import cogoToast from 'cogo-toast';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const GiftcardRatesPage = ({subcategory, fetchSubcategory, loader, fetchCategory, category, addSubCategory, deleteSubCategory}) => {


    const [value, setValue] = useState('');

    useEffect(()=>{
      fetchSubcategory()
      fetchCategory()
    },[fetchSubcategory, fetchCategory])

    const navigate = useNavigate()

    const [tabData] = useState([
        { id: 1, name: "tab-1", text: "New Rates" },
        { id: 2, name: "tab-2", text: "View Rates" },
      ]);


    const [initialTab, setTab] = useState(1);

    // tab layout
  const tabLayout = tabData.map((item) => (
    <div  key={item.id} 
    className={initialTab === item.id ? `${styles.activeTab} ${styles.tab}` : `${styles.tab}`}
        >
        <p onClick={() => handleToggle(item.id)} className='mb-0'>{item.text}</p>
    </div>
  ));

  const handleToggle = (id) => {
    setTab(id)
  }


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    handleSubmit,
  } = useFormik({
    initialValues: {
      category: "",
      subcategory: "",
      NGNRate: "",
      minimumUsdValue: "",
      maximumUsdValue: "",
      terms: ""
    },
    validationSchema: AddGiftcardValidator,
    onSubmit(values, { setSubmitting }) {
      handSubmit(values, setSubmitting);
    },
  });

  const handSubmit = async (values, setSubmitting) => {
    setSubmitting(true)
    if(value === ''){
      setSubmitting(false)
        cogoToast.info('Terms and Conditions is required')
    }
    else{
      // get category name
      let categoryname = category.find(resp => resp._id === values.category).categoryname 
        let resp = {
            categoryId : values.category,
            categoryname: categoryname,
            subcategoryname: values.subcategory,
            termsandconditions: value,
            nairarate: parseFloat(values.NGNRate),
            minimumAmount:  parseFloat(values.minimumUsdValue),
            maximumAmount: parseFloat(values.maximumUsdValue)
        }
        // make api call to add to an Existing category a giftcard
      await addSubCategory(resp)

      //    route
      setTimeout(() => {
          setSubmitting(false)
      }, 1000);

    }
  }


  const columns = [
    {
      name: "Category",
      cell: (row) => (
        <span className={styles.recent_table_row}>{`${row.categoryname}`}</span>
      ),
    },
    {
      name: "SubCategory",
      cell: (row) => (
        <span className={styles.recent_table_row}>{`${row.subcategoryname}`}</span>
      ),
    },
    {
        name: "Minimum Amount",
        cell: (row) => (
          <span className={styles.recent_table_row}>{`${row.minimumAmount}`}</span>
        ),
      },
      {
        name: "Maximum Amount",
        cell: (row) => (
          <span className={styles.recent_table_row}>{`${row.maximumAmount}`}</span>
        ),
      },
      {
        name: "Naira Rate",
        cell: (row) => (
          <span className={styles.recent_table_row}>{`${row.nairarate}`}</span>
        ),
      },
    {
      name: "Date Added",
      cell: (row) => (
        <span className={styles.recent_table_row}>
          <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
        </span>
      ),
    },
    {
        name: "Actions",
        button: true,
        cell: (row) => (
          <button
            className="btn btn-sm btn-view"
            onClick={() => {
              ViewRequest(row._id);
            }}
          >
            View
          </button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <button
            className="btn btn-sm btn-view"
            onClick={() => {
              DeleteRate(row._id, row.categoryId)}}
          >
            Delete
          </button>
        ),
      },
  ];

  const DeleteRate = (id, categoryId) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `You are about to delete this giftcard, Do you wish to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id, categoryId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDelete = (id, categoryId) =>{
    deleteSubCategory(id, categoryId)

      setTimeout(() => {
        fetchSubcategory()
      }, 2000);
  }

  const ViewRequest = (id) =>{
    navigate(`/giftcardrate/edit/${id}`)
  }

 
    return ( 
        <>
             <UserSidebar>
            <div>
                <p className="titlehead">Giftcard Rates</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <div className={`mt-5 ${styles.TabHeading}`}>
                    {tabLayout}
             </div>

             {
                initialTab === 1 ?
                <div className={`mt-5 ${styles.rates_card}`}>

                     <h4 className={styles.title}>Add New Giftcard</h4>

                <form onSubmit={handleSubmit}>

                 <div className="row mt-4">
                     <div className="col-lg-6">
                        {/* form submission */}
                   
                        {/* Category */}
                        <div 
                        className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="category">Category</label>
                        <select
                            name="category"
                            values={values.category}
                            onChange={(e) => {
                            handleChange(e);
                            }}
                            onBlur={handleBlur}
                            defaultValue=""
                            className={`${styles.input_style}`}
                            id="category"
                        >
                            <option value="" disabled>Select a category</option>
                            {category.map((opt, index) => {
                              return (
                                <option key={index} value={opt._id}>
                                  {opt.categoryname}
                                </option>
                              );
                            })}
                        </select>
                        <small style={{ color: "#dc3545" }}>
                            {touched.category && errors.category}
                        </small>
                        </div>

                        {/* subcategory */}
                        <div 
                        className={`mb-3 ${styles.input_container}`}>
                        <label htmlFor="subcategory">Enter a Subcategory</label>
                        <input
                            className={`${styles.input_style}`}
                            type="text"
                            placeholder="Amazon"
                            onBlur={handleBlur}
                            onChange={(e) => {
                            handleChange(e);
                            }}
                            id="subcategory"
                            style={{ width: "100%" }}
                            value={values.subcategory}
                        />
                        <small style={{ color: "#dc3545" }}>
                            {touched.subcategory && errors.subcategory}
                        </small>
                        </div>                 

                        <div className="row mt-2">
                        <div className="col-lg-6">
                            {/* ghs rate */}
                            <div 
                            className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="minimumUsdValue">Minimum USD Amount</label>
                            <input
                                className={`${styles.input_style}`}
                                type="text"
                                placeholder="0"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                id="minimumUsdValue"
                                style={{ width: "100%" }}
                                value={values.minimumUsdValue}
                            />
                            <small style={{ color: "#dc3545" }}>
                                {touched.minimumUsdValue && errors.minimumUsdValue}
                            </small>
                            </div>

                        </div>

                        <div className="col-lg-6">
                                {/* Ngn rate */}
                            <div 
                            className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="maximumUsdValue">Maximum USD Amount</label>
                            <input
                            className={`${styles.input_style}`}
                                type="text"
                                placeholder="0"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                id="maximumUsdValue"
                                style={{ width: "100%" }}
                                value={values.maximumUsdValue}
                            />
                            <small style={{ color: "#dc3545" }}>
                                {touched.maximumUsdValue && errors.maximumUsdValue}
                            </small>
                        </div>

                        </div>

                        </div>

                        <div className="row">
                        <div className="col-lg-6">
                                {/* Ngn rate */}
                            <div
                            className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="NGNRate">NGN Rate</label>
                            <input
                                className={`${styles.input_style}`}
                                type="text"
                                placeholder="0"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                id="NGNRate"
                                style={{ width: "100%" }}
                                value={values.NGNRate}
                            />
                            <small style={{ color: "#dc3545" }}>
                                {touched.NGNRate && errors.NGNRate}
                            </small>
                        </div>

                        </div>

                        </div>   
                
               
                      </div>

                    <div className="col-lg-6">

                         <div
                            className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="terms">Terms and Conditions</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={values.terms}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setValue(data)
                                    
                                } }
                               
                            /> 
                             <small style={{ color: "#dc3545" }}>
                                  {value === '' ? 'Terms and Conditions is required' : ''}
                              </small> 
                        </div>
              

                     </div>

                     </div>

                     <div className='text-center'>
                        <button
                        type="submit"
                        className={`btn mt-4 ${styles.btn_add}`}
                        disabled={isSubmitting}
                        >
                        Add Giftcard
                        </button>

                    </div>
                 </form>


                </div>
                :
                <>
                 <div className={`mt-5 ${styles.ratestables_div}`}>
                    <DataTable
                    title="Rates"
                    pagination
                    persistTableHead
                    columns={columns}
                    data={subcategory}
                    progressPending={loader}
                    />
                </div>
                </>
                }



            </div>
        </div>
        </>
     );
}



const mapStateToProps = (state) =>{
  return{
      subcategory: state.giftrate.subcategory,
      loader: state.giftrate.loader,
      category: state.giftrate.category
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      fetchSubcategory: () => dispatch(getSubCategory()),
      fetchCategory: () => dispatch(getCategory()),  
      addSubCategory: (val) => dispatch(CreateSubCategory(val)), 
      deleteSubCategory: (id, categoryId) => dispatch(deleteGiftCards(id, categoryId)), 
  };
};

 
export default connect(mapStateToProps, mapDispatchToProps)(GiftcardRatesPage);