import React,{useState, useEffect} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './transaction.module.css'
import DataTable from "react-data-table-component";
import hamburger from "../../../assets/icons/align-left.svg";
import Modal from "../../../components/Modals/FilterModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik } from "formik";
import { filterValidator } from "../../../validationSchema/validator";
import { useNavigate } from 'react-router-dom';
import { getFilteredTransactions, getTransactions } from '../../../store/actions/transactions';
import { connect } from 'react-redux';
import Moment from "react-moment";

const AdminTransactions = ({fetchTransactions, transactions, loader, fetchFilteredTransactions}) => {

    const [search, setSearchValue] = useState("");


    useEffect(()=>{
      let status = 'All'
      fetchTransactions(status)
    },[fetchTransactions])


    const navigate = useNavigate()

    const [showFilterModal, setFilterModal] = useState(false);

    const [startDate, setStartDate] = useState(new Date());

    const [endDate, setEndDate] = useState(new Date());

    const columns = [
        {
          name: "Type",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`${row.type}`}</span>
          ),
        },
        {
          name: "Amount",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`NGN ${row.amount}`}</span>
          ),
        },
        {
          name: "Date",
          cell: (row) => (
            <span className={styles.recent_table_row}>
              <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
            </span>
          ),
        },
        {
          name: "Status",
          cell: (row) => (
            <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
          ),
        },
        {
          name: "Action",
          button: true,
          cell: (row) => 
          (
            <button
            className="btn btn-sm btn-view"
            onClick={() => {
              ViewRequest(row._id);
            }}
          >
            View
          </button>
          ),
        },
      ];

      const ViewRequest = (id) =>{
        navigate(`/transaction/${id}`)
      }

      
      const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending_icon";
            break;
          case "Successful":
            result = "completed_icon";
            break;
          case "Failed":
            result = "failed_icon";
            break;
          default:
            result = "failed_icon";
            break;
        }
        return result;
      };

    
    const handleChange = (e) => {
       setSearchValue(e.target.value);
    };

    const formatDate = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  
      return [year, month, day].join("-");
    };

    const handleSubmit = async (values, setSubmitting) => {
      let res;
      res = {
        from_date: formatDate(startDate),
        to_date: formatDate(endDate),
        status: values.status,
      };
      console.log(res);
      setFilterModal(false)
      await fetchFilteredTransactions(values.status, formatDate(startDate), formatDate(endDate))
    };
  
    

    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Transactions</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <Modal
              title="Filter"
              show={showFilterModal}
              onClose={() => setFilterModal(false)}
            >
               <div>
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={filterValidator}
                initialValues={{
                  amount: "",
                  status: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* Date*/}
                    <div className="row mt-4">
                      <div className="col-lg-6">
                        <div 
                         className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="startDate">From Date</label>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className={`${styles.filter_input_style}`}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                         className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="startDate">To Date</label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className={`${styles.filter_input_style}`}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                    </div>

                    {/* status */}
                    <div 
                     className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        values={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue=""
                        className={`${styles.filter_input_style}`}
                        id="status"
                      >
                        <option value="" disabled>--Select--</option>
                        <option value="All">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Successful">Successful</option>
                        <option value="Failed">Failed</option>
                      </select>
                      <small style={{ color: "#dc3545" }}>
                        {touched.status && errors.status}
                      </small>
                    </div>

                    <div className="text-center mt-4 ">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_filter}`}
                      >
                        Apply Filter
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            </Modal>


                {/* trades section table */}
                <div className={`mt-5 mb-5 ${styles.trades_div}`}>

                     {/* filter layout and search */}
                    <div className={styles.trade_filter_container}>
                        <div className={styles.transaction_search}>
                         <div className={`mb-3 mb-md-0 ${styles.search_container}`}>
                                <input
                                    className={styles.input_style}
                                    type="text"
                                    placeholder="Search by Type, Amount"
                                    id="reference"
                                    value={search}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    <div className='ml-lg-4 ml-0'>
                    <button
                        className={`btn ${styles.filter_div}`}
                        onClick={() => setFilterModal(true)}
                        >
                        <img
                            src={hamburger}
                            width="20"
                            height="20"
                            alt="hamburger"
                            className="mr-2"
                        />
                        Filter By
                        </button>
                        </div>
                    </div>

                    <DataTable
                    title="Transactions"
                    pagination
                    persistTableHead
                    columns={columns}
                    data={transactions}
                    progressPending={loader}
                    />

                </div>

            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
  return {
    transactions: state.transaction.transactions,
    loader: state.transaction.loader
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactions: (status) => dispatch(getTransactions(status)),
    fetchFilteredTransactions: (status, from, end) => dispatch(getFilteredTransactions(status, from, end)),
  };
};
 
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminTransactions);