import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// private routes
import AdminRoute from "./components/PrivateRoutes/userRoute";

import NotFoundPage from "./pages/404/NotFound";
import AdminManagePage from "./pages/Admin/Admin/Admin";
import EditCryptoRates from "./pages/Admin/CryptoRates/editRate";
import CryptoRatesPage from "./pages/Admin/CryptoRates/Rates";
import AdminDashboard from "./pages/Admin/Dashboard/Dashboard";
import EditGiftcardRates from "./pages/Admin/GiftcardRates/editRate";
import GiftcardRatesPage from "./pages/Admin/GiftcardRates/Rates";
import AdminProfilePage from "./pages/Admin/Profile/profile";
import AdminRequestDetailsPage from "./pages/Admin/Requests/RequestDetails";
import AdminRequestsPage from "./pages/Admin/Requests/requests";
import AdminWithdrawSettings from "./pages/Admin/Settings/withdrawSettings";
import AdminCrytoDetailsPage from "./pages/Admin/Trades/CryptoDetails";
import GiftCardDetails from "./pages/Admin/Trades/GiftcardDetails";
import AdminTrades from "./pages/Admin/Trades/trades";
import AdminTransactionDetailsPage from "./pages/Admin/Transactions/transactionDetails";
import AdminTransactions from "./pages/Admin/Transactions/transactions";
import UserInformation from "./pages/Admin/Users/userInfo";
import AdminUsersPage from "./pages/Admin/Users/users";
import ForgotPasswordPage from "./pages/Auth/forgot";
import LoginPage from "./pages/Auth/login";
import VerifySuccessPage from "./pages/Auth/verifySuccess";
import ResetPasswordPage from "./pages/Auth/resetPassword";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/verifyemail/:code" element={<VerifySuccessPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />

        <Route path="/overview" element={
          <AdminRoute>
              <AdminDashboard />
          </AdminRoute>        
        } />
        <Route path="/trades" element={
          <AdminRoute>
            <AdminTrades />
          </AdminRoute>
          } />
        <Route path="/trade/crypto/:id" element={
            <AdminRoute>
               <AdminCrytoDetailsPage/>
            </AdminRoute>
            } />
        <Route path="/trade/giftcard/:id" element={
             <AdminRoute>
              <GiftCardDetails />
            </AdminRoute>
            } />
        <Route path="/transactions" element={
               <AdminRoute>
                  <AdminTransactions />
              </AdminRoute>
              } />
        <Route path="/transaction/:id" element={
             <AdminRoute>
              <AdminTransactionDetailsPage />
              </AdminRoute>
              } />
        <Route path="/requests" element={
          <AdminRoute>
            <AdminRequestsPage />
            </AdminRoute>
            } />
        <Route path="/request/:id" element={
          <AdminRoute>
            <AdminRequestDetailsPage/>
          </AdminRoute>
        } />
        <Route path="/users" element={
           <AdminRoute>
             <AdminUsersPage/>
            </AdminRoute>
            } />
        <Route path="/user/:id" element={
             <AdminRoute>
              <UserInformation/>
              </AdminRoute>
              } />
        <Route path="/cryptorates" element={
             <AdminRoute>
              <CryptoRatesPage/>
            </AdminRoute>
            } />
        <Route path="/cryptorate/edit/:id" element={
          <AdminRoute>
            <EditCryptoRates />
            </AdminRoute>
            } />
        <Route path="/giftcardrates" element={
          <AdminRoute>
            <GiftcardRatesPage />
            </AdminRoute>
            } />
        <Route path="/giftcardrate/edit/:id" element={
          <AdminRoute>
            <EditGiftcardRates/>
            </AdminRoute>
            } />
        <Route path="/profile" element={
            <AdminRoute>
              <AdminProfilePage/>
          </AdminRoute>
        } />
        <Route path="/admin" element={
              <AdminRoute>
              <AdminManagePage />
              </AdminRoute>
              } />
        <Route path="/settings" element={
              <AdminRoute>
                <AdminWithdrawSettings/>
              </AdminRoute>
              } />
        <Route path="*" element={<NotFoundPage />}/>
      </Routes>
    </Router>
  );
}

export default App;
