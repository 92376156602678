import React,{useEffect, useState} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './users.module.css'
import DataTable from "react-data-table-component";
import Modal from "../../../components/Modals/FilterModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Formik } from "formik";
import { filterValidator } from "../../../validationSchema/validator";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { filterUsers, getUsers } from '../../../store/actions/users';
import Moment from "react-moment";

const AdminUsersPage = ({fetchUsers, users, loader, usersFilter}) => {

  const navigate = useNavigate()

    const [showFilterModal, setFilterModal] = useState(false);

    const [startDate, setStartDate] = useState(new Date());

    const [endDate, setEndDate] = useState(new Date());

    useEffect(()=>{
       fetchUsers()
    },[fetchUsers])

    const columns = [
        {
          name: "First Name",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`${row.firstName}`}</span>
          ),
        },
        {
          name: "Last Name",
          cell: (row) => (
            <span className={styles.recent_table_row}>{`${row.lastName}`}</span>
          ),
        },
        {
          name: "Email Address",
          cell: (row) => (
            <span className={styles.recent_table_row}>
              {row.email}
            </span>
          ),
        },
        {
            name: "Date Registered",
            cell: (row) => (
              <span className={styles.recent_table_row}>
                 <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
              </span>
            ),
          },
        {
          name: "Status",
          cell: (row) => (
            <span>{`${row.isEnabled ? 'Active' : 'InActive'}`}</span>
          ),
        },
        {
          name: "Action",
          button: true,
          cell: (row) =>  (
            <button
            className="btn btn-sm btn-view"
            onClick={() => {
              ViewRequest(row._id);
            }}
          >
            View
          </button>
          ),
        },
      ];


      const ViewRequest = (id) =>{
        usersFilter(id)
        navigate(`/user/${id}`)
      }
      
      

    const formatDate = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  
      return [year, month, day].join("-");
    };

    const handleSubmit = async (values, setSubmitting) => {
      let res;
      res = {
        from_date: formatDate(startDate),
        to_date: formatDate(endDate),
        status: values.status,
      };
      console.log(res);
    };
  
    

    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Users</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

            <Modal
              title="Filter"
              show={showFilterModal}
              onClose={() => setFilterModal(false)}
            >
               <div>
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={filterValidator}
                initialValues={{
                  amount: "",
                  status: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* Date*/}
                    <div className="row mt-4">
                      <div className="col-lg-6">
                        <div 
                         className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="startDate">From Date</label>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className={`${styles.filter_input_style}`}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                         className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="startDate">To Date</label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className={`${styles.filter_input_style}`}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </div>
                    </div>

                    {/* status */}
                    <div 
                     className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        values={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue=""
                        className={`${styles.filter_input_style}`}
                        id="status"
                      >
                        <option value="" disabled>--Select--</option>
                        <option value="All">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Successful">Successful</option>
                        <option value="Failed">Failed</option>
                      </select>
                      <small style={{ color: "#dc3545" }}>
                        {touched.status && errors.status}
                      </small>
                    </div>

                    <div className="text-center mt-4 ">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_filter}`}
                      >
                        Apply Filter
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            </Modal>


                {/* trades section table */}
                <div className={`mt-5 mb-5 ${styles.trades_div}`}>

                    <DataTable
                    title="Users"
                    pagination
                    persistTableHead
                    columns={columns}
                    data={users}
                    progressPending={loader}
                    />

                </div>

            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) =>{
  return{
      users: state.user.users,
      loader: state.user.loader
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      fetchUsers: (values) => dispatch(getUsers(values)),
      usersFilter: (id) => dispatch(filterUsers(id)),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);