import React,{useEffect, useState} from 'react';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './rates.module.css'
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import { AddRateValidator } from "../../../validationSchema/validator";
import cogoToast from 'cogo-toast';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { addRate, clearRateSuccess, deleteRate, getCoinTypes, getRates } from '../../../store/actions/cryptorates';
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const CryptoRatesPage = ({fetchRate, rates, rateDelete, loader, add, cointype, coinTypeFetch, ratesuccess, clearRate}) => {


    useEffect(()=>{
        fetchRate()
        coinTypeFetch()
    },[fetchRate, coinTypeFetch])

    const [tabData] = useState([
        { id: 1, name: "tab-1", text: "New Rates" },
        { id: 2, name: "tab-2", text: "View Rates" },
      ]);

      const [isSaveDisabled, setIsSaveDisabled] = useState(false)

      const navigate = useNavigate()

    const [initialTab, setTab] = useState(1);

    // tab layout
  const tabLayout = tabData.map((item) => (
    <div  key={item.id} 
    className={initialTab === item.id ? `${styles.activeTab} ${styles.tab}` : `${styles.tab}`}
        >
        <p onClick={() => handleToggle(item.id)} className='mb-0'>{item.text}</p>
    </div>
  ));

  const handleToggle = (id) => {
    setTab(id)
    if(id === 2){
      fetchRate()
    }
  }


  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      coinType: "",
      USDRate: "",
      NGNRate: "",
      minimumUsdValue: "",
      maximumUsdValue: ""
    },
    validationSchema: AddRateValidator,
    onSubmit(values, { setSubmitting }) {
      handSubmit(values, setSubmitting);
    },
  });

  const handSubmit = async (values) => {
    let minValue = values.minimumUsdValue
    let maxValue = values.maximumUsdValue

        // check if min/max value already exist in prev array
        const found = addedRate.some(el => parseFloat(el.minimumUsdValue) === parseFloat(minValue) || parseFloat(el.maximumUsdValue) === parseFloat(maxValue));
        if(found){
        cogoToast.warn("There already exist a minimum or maximum value added!")
        }
        else{
            // Push newly added values into the added rate array 
            let res = {
            minimumUsdValue: parseFloat(minValue),
            maximumUsdValue: parseFloat(maxValue),
            ngnRateUsd: parseFloat(values.NGNRate),
            }
        setAddedRate([
            ...addedRate,
            res
        ])
    }
  }


  const column1 = [
    {
      name: "Min USD",
      selector: (row) => row.minimumUsdValue,
    },
    {
      name: "Max USD",
      selector: (row) => <span>{`${row.maximumUsdValue}`}</span>,
    },
    {
      name: "NGN Rate",
      selector: (row) => <span>{`${row.ngnRateUsd}`}</span>,
    },
    {
      name: "Actions",
      button: true,
      cell: (row,index) => (
        <button
          className="btn btn-sm btn-view"
          onClick={() => {
            handleRemove(row, index);
          }}
        >
          Remove
        </button>
      ),
    },
  ];

  const [addedRate, setAddedRate] = useState([])

  const [coinType, setCoinType] = useState("")
  const [usdRate, setUsdRate] = useState(0)

  const handleUSDRate = (val) =>{
    setUsdRate(val)
  }

  const handleCoinType = (val) =>{
    setCoinType(val)
    setUsdRate(0)
    setAddedRate([])
    setFieldValue("USDRate", "")
    setFieldValue("minimumUsdValue", "")
    setFieldValue("maximumUsdValue", "")
    setFieldValue("NGNRate", "")
  }


  const handleRemove = (row, index) =>{
    let res = [...addedRate]
    res.splice(index, 1)
    setAddedRate(res)
  }


  const handleAddRate = async () =>{
    setIsSaveDisabled(true)  
    let result = {
      localCurrencyRate: addedRate,
      usdRateCoin: usdRate,
      coinType: coinType
    }
    await add(result)

    setTimeout(()=>{
      setIsSaveDisabled(false)
    }, 3000)
  }


  useEffect(() => {
    if (ratesuccess) {
      // reset the form
      setTimeout(() => {
        clearRate();
        fetchRate()
        setTab(2)

      }, 2000);
    }
  }, [ratesuccess, clearRate, fetchRate]);


  const columns = [
    {
      name: "Coin Name",
      cell: (row) => (
        <span className={styles.recent_table_row}>{`${row.coinType}`}</span>
      ),
    },
    {
      name: "USD Rate",
      cell: (row) => (
        <span className={styles.recent_table_row}>{`USD ${row.usdRateCoin}`}</span>
      ),
    },
    {
      name: "Date Added",
      cell: (row) => (
        <span className={styles.recent_table_row}>
          <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
        </span>
      ),
    },
    {
        name: "Actions",
        button: true,
        cell: (row) => (
          <button
            className="btn btn-sm btn-view"
            onClick={() => {
              ViewRequest(row._id);
            }}
          >
            View
          </button>
        ),
      },
      {
        name: "",
        button: true,
        cell: (row) => (
          <button
            className="btn btn-sm btn-view"
            onClick={() => {
              DeleteDialog(row._id);
            }}
          >
            Delete
          </button>
        ),
      },
  ];

  const DeleteDialog = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: `You are about to delete this rate, Do you wish to continue?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => ApproveDelete(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const ApproveDelete = (id) => {
    rateDelete(id)

    setTimeout(()=>{
      fetchRate()
    }, 2000)
  };

  const ViewRequest = (id) =>{
    navigate(`/cryptorate/edit/${id}`)
  }


    return ( 
        <>
        <UserSidebar>
            <div>
                <p className="titlehead">Crypto Rates</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">

                <div className={`mt-5 ${styles.TabHeading}`}>
                    {tabLayout}
                 </div>

                 {
                initialTab === 1 ?
                <div className={`mt-5 ${styles.rates_card}`}>

                     <h4 className={styles.title}>Create New Rate</h4>

                     <div className="row mt-4">
                <div className="col-lg-6">

                    {/* form submission */}
        
                  <form onSubmit={handleSubmit}>
                    {/* Coin Name */}
                    <div 
                       className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="coinType">Coin Name</label>
                      <select
                        name="coinType"
                        values={values.coinType}
                        onChange={(e) => {
                          handleChange(e);
                          handleCoinType(e.currentTarget.value)
                        }}
                        onBlur={handleBlur}
                        defaultValue=""
                        className={`${styles.input_style}`}
                        id="coinType"
                      >
                        <option value="" disabled>Select a coin type</option>
                        {cointype.map((opt, index) => {
                          return (
                            <option key={index} value={opt.coinType}>
                              {opt.coinType}
                            </option>
                          );
                        })}
                      </select>
                      <small style={{ color: "#dc3545" }}>
                        {touched.coinType && errors.coinType}
                      </small>
                    </div>

                    {/* usd rate */}
                    <div 
                       className={`mb-3 ${styles.input_container}`}>
                      <label htmlFor="USDRate">USD Rate</label>
                      <input
                         className={`${styles.input_style}`}
                        type="text"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          handleUSDRate(e.currentTarget.value);
                        }}
                        id="USDRate"
                        style={{ width: "100%" }}
                        value={values.USDRate}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.USDRate && errors.USDRate}
                      </small>
                    </div>

                    
                    <div className="mt-4">
                      <small>
                      <i
                        className="mdi mdi-information mr-1"
                        style={{ color: "#FF8400" }}
                      ></i>
                      Kindly add a minimum and a maximum breakdown here.
                    </small>
                    </div>

                    <div className="row mt-2">
                      <div className="col-lg-6">
                          {/* ghs rate */}
                        <div 
                           className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="minimumUsdValue">Minimum USD Amount</label>
                          <input
                            className={`${styles.input_style}`}
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="minimumUsdValue"
                            style={{ width: "100%" }}
                            value={values.minimumUsdValue}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.minimumUsdValue && errors.minimumUsdValue}
                          </small>
                        </div>

                      </div>

                      <div className="col-lg-6">
                              {/* Ngn rate */}
                        <div 
                           className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="maximumUsdValue">Maximum USD Amount</label>
                          <input
                           className={`${styles.input_style}`}
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="maximumUsdValue"
                            style={{ width: "100%" }}
                            value={values.maximumUsdValue}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.maximumUsdValue && errors.maximumUsdValue}
                          </small>
                      </div>

                      </div>

                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                              {/* Ngn rate */}
                        <div
                           className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="NGNRate">NGN Rate</label>
                          <input
                            className={`${styles.input_style}`}
                            type="text"
                            placeholder="0"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            id="NGNRate"
                            style={{ width: "100%" }}
                            value={values.NGNRate}
                          />
                          <small style={{ color: "#dc3545" }}>
                            {touched.NGNRate && errors.NGNRate}
                          </small>
                      </div>

                      </div>

                    </div>   
                
                    <button
                      type="submit"
                     className={`btn mt-4 ${styles.btn_add}`}
                     disabled={isSaveDisabled}
                    >
                      Add
                    </button>
                    <small>
                      <i
                        className="mdi mdi-information mr-1"
                        style={{ color: "#FF8400" }}
                      ></i>
                      The coin name selected is what is displayed to the user.
                    </small>
                  </form>
               
                </div>

                <div className="col-lg-6">
                        
                    <div className="mt-4">
                    <DataTable
                        title="Added Rates"
                        columns={column1}
                        data={addedRate}
                        pagination
                        persistTableHead
                        progressPending={false}
                    />
                    </div>

                    <div>
                    <button
                        type="submit"
                        className={`btn mt-4 ${styles.btn_add}`}
                        onClick={handleAddRate}
                        disabled={addedRate.length === 0 || isSaveDisabled}
                        >
                        Save Rate
                        </button>
                    </div>
                

                </div>

                     </div>



                </div>
                :
                <>
                 <div className={`mt-5 ${styles.ratestables_div}`}>
                    <DataTable
                    title="Rates"
                    pagination
                    persistTableHead
                    columns={columns}
                    data={rates}
                    progressPending={loader}
                    />
                </div>
                </>
                }


                </div>
            </div>
        </>
     );
}


const mapStateToProps = (state) =>{
  return{
    rates: state.cryptorate.rates,
    loader: state.cryptorate.loader,
    cointype: state.cryptorate.cointypes,
    ratesuccess: state.cryptorate.ratesuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      fetchRate: () => dispatch(getRates()),
      rateDelete: (id) => dispatch(deleteRate(id)),
      add: (creds) => dispatch(addRate(creds)),
      clearRate: () => dispatch(clearRateSuccess()),
      coinTypeFetch: () => dispatch(getCoinTypes()),
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(CryptoRatesPage);