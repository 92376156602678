import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserSidebar from '../../../components/Sidebar/sidebar';
import styles from './trade.module.css'
import ImageZoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from "react-moment";
import { approveTrade, clearImageSuccess, clearRequest, declineTrade, uploadImage } from '../../../store/actions/trades';
import Modal from "../../../components/Modals/declineModal";
import { Form, Formik } from "formik";
import { declineValidator } from "../../../validationSchema/validator";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const GiftCardDetails = ({loader, trades, handleApprove, handleDecline, requestSuccess, handleClear, handleUpload, imageAdded, imageLoader, handleClearImage}) => {

    const {id} = useParams()

    const [showDeclineModal, setDeclineModal] = useState(false);

    const trade = trades.find((val) => val._id === id);

    const navigate = useNavigate()

       // mapping images
       const imageLayout = trade.imageUrl
       .filter((el) => el !== "")
       .map((item, index) => (
           <div key={index} className='col-lg-6 col-6 mb-3'>
            <div>
               <ImageZoom>
                   <img
                   alt="cards"
                   src={item}
                   className={styles.cardImage}
                   />
               </ImageZoom>
            </div>
           </div>
       ));


    const Approve = () => {
        confirmAlert({
          title: "Confirm to submit",
          message: `You are about to approve this trade, Do you wish to continue?`,
          buttons: [
            {
              label: "Yes",
              onClick: () => TradeApprove(),
            },
            {
              label: "No",
            },
          ],
        });
      };
    
      const TradeApprove = () => {
        handleApprove(id)
      };

      
    const Decline = () => {
      setDeclineModal(true)
    };

    const fileInputRef = useRef();

    const fileobj= [];

    const changedHandler = () => {
      let files = fileInputRef.current.files;
      fileobj.push(files);
      handleUpload(files[0])
  }

    const handleSubmit = async (values) => {   
      const creds = {
          id,
          imageUrl: imageAdded.hasOwnProperty('fileUrl') ? imageAdded.fileUrl : "",
          reason: values.reason
      }
      console.log(creds)
      await handleDecline(creds)
    };
 
   const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result =  styles.pending_icon
            break;
          case "Completed":
            result = styles.completed_icon
            break;
          case "Declined":
            result =  styles.failed_icon
            break;
          default:
            break;
        }
        return result;
    };

    const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }

    useEffect(()=>{
     if(requestSuccess){
            setTimeout(()=>{
                navigate('/trades')
                handleClear()
                handleClearImage()
            }, 2000)      
        }
    },[navigate, requestSuccess, handleClear, handleClearImage])
          

    return ( 
        <>
         <UserSidebar>
            <div>
                <p className="titlehead">Trade History</p>
            </div>
        </UserSidebar>
        <div className="usermain">
            <div className="contain-side">


            <Modal
              title="Decline Trade"
              show={showDeclineModal}
              onClose={() => setDeclineModal(false)}
            >
               <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={declineValidator}
                initialValues={{
                  reason: "",
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>

                      <div className={`mb-3 ${styles.input_container}`}>
                          <label htmlFor="reason">Reason</label>
                          <div style={{ position: "relative" }}>
                              <textarea
                                  className={`${styles.input_style}`}
                                  type="text"
                                  placeholder="Enter a reason for decline"
                                  id="reason"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.reason}
                                  style={{width: '100%', resize: 'none'}}
                                  rows="6"
                              />
                            
                          </div>
                          <small style={{ color: "#dc3545" }}>
                              {touched.reason && errors.reason}
                          </small>
                      </div>

                      <div className={`mb-3 ${styles.input_container}`}>
                          <div className={`${styles.input_group} ${styles.cursor_pointer}`}>
                            <div className={styles.custom_file}>
                              <input accept="image/*"      
                                    ref={fileInputRef}  
                                  onChange={changedHandler}
                                  className={styles.custom_file_input} type="file" />
                              <label className={styles.custom_file_label}>
                                Attach an image
                              </label>
                          </div>
                          </div>         
                      </div> 

                      <div className={styles.fileupload}>
                        <div className="row">                            
                            <div className='col-4 mb-3'>
                              {imageLoader &&  <Skeleton count={1} style={{height: '100px'}} /> }
                             {
                               imageAdded.hasOwnProperty('fileUrl') ?
                                <div className={styles.image_preview}>
                                  <img src={imageAdded.fileUrl} className='img-fluid' alt="preview" />
                                </div>
                              :
                              ""
                             } 
                              
                            </div>
                        </div>                                    
                    </div>

                    <div className="text-center mt-5">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn ${styles.btn_filter}`}
                      >
                        Decline Trade
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>

            </Modal>

            <div className={`mt-5 ${styles.details_card}`}>

                <Link to="/trades" className={styles.goback_div}>
                    <i className='mdi mdi-chevron-left mr-1' style={{fontSize: '20px'}}></i>
                    <p className='mb-0' style={{fontSize: '14px'}}>Go Back</p>
                </Link>

                <div className='text-center mt-md-3 mt-3'>
                    <h4 className={styles.title}>Trade Details</h4>
                </div>

                <div className='row mt-md-5 mt-4'>
                    <div className='col-lg-6'>
                        <div className='mb-4'>
                            <h6 className={styles.details_caption}>Trade Id</h6>
                            <p className={styles.details_text}>{trade._id}</p>
                        </div>

                        <div className='mb-4'>
                            <h6 className={styles.details_caption}>Date</h6>
                            <p className={styles.details_text}>
                            <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                {trade.createdAt}
                                </Moment>
                                </p>
                        </div>

                        <div className='mb-4'>
                            <h6 className={styles.details_caption}>Name</h6>
                            <p className={styles.details_text}>{trade.userDetails.firstName} {trade.userDetails.lastName}</p>
                        </div>

                        <div className='mb-4'>
                            <h6 className={styles.details_caption}>Trade Type</h6>
                            <p className={styles.details_text}>Giftcard</p>
                        </div>


                        <div className='mb-4'>
                            <h6 className={styles.details_caption}>Card Name</h6>
                            <p className={styles.details_text}>{trade.subCategoryDetails.categoryname}</p>
                        </div>


                        <div className='mb-4'>
                            <h6 className={styles.details_caption}>Card Category</h6>
                            <p className={styles.details_text}>{trade.subCategoryDetails.subcategoryname}</p>
                        </div>

                        <div className='mb-4'>
                                <h6 className={styles.details_caption}>Comment</h6>
                                <p className={`mt-1 ${styles.details_text}`}>{trade.comment}</p>
                        </div>


                        <div className='mb-4'>
                            <h6 className={styles.details_caption}>Amount Traded</h6>
                            <p className={styles.details_text}>USD {trade.cardAmount}</p>
                        </div>

                        <div className='mb-4'>
                            <h6 className={styles.details_caption}>Amount Due</h6>
                            <p className={styles.details_text}>NGN {numberWithCommas(trade.amount)}</p>
                        </div>

                        <div>
                            <h6 className={styles.details_caption}>Trade Status</h6>
                            <div className={styles.status_div}>
                                <p 
                                className={`mt-1 ${getStatusColor(trade.tradeStatus)}`}>{trade.tradeStatus}</p>
                            </div>
                        </div>
                    </div>
                   

                    <div className='col-lg-6'>
                         <div>
                             <h6 className={styles.details_caption}>Uploaded Cards</h6> 
                        </div>

                          {/* cards */}
                          <div className='row mt-3 mb-3'>
                                {imageLayout}
                          </div>
                         
                            {
                          trade.tradeStatus === 'Declined' &&
                          <>
                            <div className='mb-4'>
                                <h6 className={styles.details_caption}>Declined Reason</h6>
                                <p className={styles.details_text}>{trade.hasOwnProperty('declinedReason') ? trade.declinedReason.reasonText : "Declined"}</p>
                            </div>
                              
                            <div className='mb-4'>
                                <h6 className={styles.details_caption}>Images Attached for Declination</h6>
                                <ImageZoom>
                                    <img
                                    alt="cards"
                                    src={trade.declinedReason.imageUrl === "" ? trade.imageUrl[0] : trade.declinedReason.imageUrl }
                                    className={`mt-2 ${styles.cardImage}`}
                                    />
                                </ImageZoom>
                            </div>
                          </>
                          }

                    </div>

                </div>

            

                  {/* approve and decline request  */}
                {
                    trade.tradeStatus === 'Pending' &&
                    <div className={`mt-4 ${styles.cta_btn}`}>
                    <button
                        type="submit"
                        className={`btn ${styles.btn_approve}`}
                       
                        disabled={loader}
                        onClick={() => {
                        Approve();
                        }}
                    >
                        Approve Trade
                    </button>
                    <button
                        type="submit"
                        className={`btn ${styles.btn_decline}`}
                        disabled={loader}
                        onClick={() => {
                        Decline();
                        }}
                    >
                        Decline Trade
                    </button>
                    
                 </div>
                }
                 

                </div>
                
            </div>
        </div>
        </>
     );
}


const mapStateToProps = (state) => {
    return {
      trades: state.trade.trades,
      loader: state.trade.load,
      requestSuccess: state.trade.requestSuccess,
      imageAdded: state.trade.imageAdded,
      imageLoader: state.trade.imageLoader
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        handleApprove : (id) => dispatch(approveTrade(id)),
        handleDecline : (id) => dispatch(declineTrade(id)),
        handleUpload : (file) => dispatch(uploadImage(file)),
        handleClear : () => dispatch(clearRequest()),
        handleClearImage : () => dispatch(clearImageSuccess()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(GiftCardDetails);